import { useTranslation } from "react-i18next";
import { UserLogin } from "../../../auth/login/models";
import { useNavigate } from "react-router";
import * as loginService from "../../../auth/services/auth.service";
import styles from './LoginForm.module.scss';
import { Form, Formik } from 'formik';
import { usePluginStore } from 'react-pluggable';

let userCredentials: UserLogin = {
    loginIdentifier: "",
    password: ""
};

const LoginFormComponent = (props: any) => {
    const { t, i18n } = useTranslation('translation');
    const navigate = useNavigate();
    const pluginStore: any = usePluginStore();
    let redirectTo: string = '';
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");
    function logIn(userCredentials: UserLogin): void {
        localStorage.removeItem('orderFormData');
        if (props.redirectToOrder === true) {
            redirectTo = '/order'
        }
        else {
            redirectTo = '/'
        }
        loginService.logIn(userCredentials, navigate, redirectTo);
    }

    return (
        <Formik initialValues={{ ...userCredentials }} onSubmit={(values) =>
            logIn(values)}>
            {({ values, handleChange, handleBlur }) => (
                <Form className={styles.form_container} onMouseEnter={() => { }}>
                    <div className={styles.field_container}>
                        <p className={styles.field_name}>{t("loginForm.enterLogin")}</p>
                        <input
                            className={styles.field_input}
                            name="loginIdentifier"
                            type="text"
                            value={values.loginIdentifier}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className={styles.field_container}>
                        <p className={styles.field_name}>{t("loginForm.password")}</p>
                        <input
                            className={styles.field_input}
                            name="password"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className={styles.remember_reset_container}>
                        <Renderer placement="loginOptions:inside" />
                        <Renderer placement="resetPassword:inside" />
                    </div>
                    <div className={styles.submit_button_container}>
                        <button type="submit">{t("loginForm.submit")}</button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default LoginFormComponent;

